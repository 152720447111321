import React from 'react';
import {
  theme,
  ThemeProvider,
  Box,
  Stack,
  Link,
  Flex,
  Input,
  Button,
  Text,
} from '@chakra-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from 'react-router-dom';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import goldBg from './assets/Gold-Glitter-Background.jpg';
import './App.css';
import Homepage from './pages/Home';
import AccommodationsPage from './pages/Accommodations';
import DetailsPage from './pages/Details';

const ourTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    gold: {
      500: '#DAA520',
      600: '#EEE8AA',
    },
    theme: {
      brown: '#4e3615',
    },
  },
  fonts: {
    ...theme.fonts,
    body: "'centaur', serif",
    // heading: "'Galada', cursive",
    heading: "'Edwardian Script', cursive",
    // heading: "'Great Vibes', cursive",
  },
};

const NavLink = ({ children, to, external, ...props }) => {
  const asProps = external
    ? { href: to, target: '_blank' }
    : { as: RouterLink, to };
  return (
    <Box {...props} maxWidth={['initial']} textAlign={['center', 'initial']}>
      <Link
        textTransform={'uppercase'}
        fontSize={16}
        color={'theme.brown'}
        {...asProps}
      >
        {children}
      </Link>
    </Box>
  );
};

function App() {
  const [pw, setPW] = React.useState(
    localStorage.getItem('toluandkevin-pw') || ''
  );
  const [displayMenu, setDisplayMenu] = React.useState(false);

  React.useEffect(() => {
    localStorage.setItem('toluandkevin-pw', pw);
  }, [pw]);

  const passwordMatch = pw.toLocaleLowerCase() === 'lovinglufkin';

  return (
    <ThemeProvider theme={ourTheme}>
      <Router>
        {!passwordMatch ? (
          <Box
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={2}
            position="relative"
          >
            <Input
              maxWidth="300px"
              margin={5}
              placeholder="Enter password"
              onChange={(e) => {
                setPW(e.target.value);
              }}
            />
          </Box>
        ) : (
          <Box
            width={[
              '90%', // base
              '85%', // 480px upwards
              '75%', // 768px upwards
              '75%', // 992px upwards
            ]}
            maxWidth="720px"
            bg="#fffff0"
            m="auto"
            minHeight={'100vh'}
            height={'100%'}
            zIndex={2}
            position="relative"
            boxShadow={'0px 0px 30px 10px ivory;'}
          >
            <Flex
              direction={'column'}
              alignItems={'center'}
              pr={5}
              pl={5}
              pb={5}
            >
              <Stack
                isInline
                spacing={4}
                mt={4}
                alignSelf="center"
                display={['none', 'flex']}
              >
                <NavLink to="/">Home</NavLink>
                <NavLink
                  to="https://forms.gle/jfjHDR68PJMCQH476"
                  external={true}
                >
                  RSVP
                </NavLink>
                <NavLink to="/accommodations">Accommodations</NavLink>
                <NavLink to="/details">Details</NavLink>
              </Stack>
              <Stack
                isInline
                spacing={4}
                mt={4}
                alignSelf="center"
                display={['flex', 'none']}
              >
                <Text
                  textTransform={'uppercase'}
                  fontSize={16}
                  color={'theme.brown'}
                  cursor="pointer"
                  id="menu-link"
                  onClick={() => {
                    setDisplayMenu(true);
                  }}
                >
                  Menu
                </Text>
              </Stack>
              <Switch>
                <Route path="/accommodations">
                  <AccommodationsPage />
                </Route>
                <Route path="/details">
                  <DetailsPage />
                </Route>
                <Route path="/">
                  <Homepage />
                </Route>
              </Switch>
            </Flex>
          </Box>
        )}
        <Box
          opacity={0.5}
          backgroundImage={` url(${goldBg})`}
          backgroundSize="cover"
          backgroundRepeat={'repeat-y'}
          position="fixed"
          top="0"
          bottom="0"
          width="100%"
          zIndex={1}
          css={{ filter: 'saturate(.9) hue-rotate(3deg)' }} // need to play around with
        ></Box>
        {displayMenu && (
          <Box
            minHeight={'100vh'}
            height={'100%'}
            width={'100vw'}
            zIndex={5}
            position="absolute"
            top={0}
            background={'#fffd'}
            display="flex"
            flexDirection="column"
          >
            <Button
              alignSelf="flex-end"
              border="none"
              background="none"
              fontWeight="500"
              fontSize="32px"
              onClick={() => setDisplayMenu(false)}
            >
              ×
            </Button>
            <Stack>
              <NavLink onClick={() => setDisplayMenu(false)} to="/">
                Home
              </NavLink>
              <NavLink
                onClick={() => setDisplayMenu(false)}
                to="https://forms.gle/jfjHDR68PJMCQH476"
                external={true}
              >
                RSVP
              </NavLink>
              <NavLink
                onClick={() => setDisplayMenu(false)}
                to="/accommodations"
              >
                Accommodations
              </NavLink>
              <NavLink onClick={() => setDisplayMenu(false)} to="/details">
                Details
              </NavLink>
            </Stack>
          </Box>
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;
