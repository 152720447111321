import React from 'react';
import {
  AspectRatioBox,
  Heading,
  Image,
  Text,
  Link,
  Box,
  Divider,
} from '@chakra-ui/core';
import headerImage from '../assets/Kevin-and-Tolu-485-121.jpg';
// import headerImage from '../assets/Kevin-and-Tolu-16-9.jpg';
// import headerImage from '../assets/Kevin-and-Tolu-1.jpg';

const Homepage = () => {
  return (
    <>
      <Heading
        fontSize={['3rem', '3.25rem']}
        lineHeight={1}
        fontWeight={500}
        margin={'32px auto 16px'}
      >
        Tolu & Kevin
      </Heading>

      <AspectRatioBox
        width="100%"
        // ratio={3 / 2}
        // ratio={16 / 9}
        ratio={485 / 121}
        mb={5}
        background={'#33170b'}
        border={'1px #dfbc76 solid'}
      >
        <Image src={headerImage} maxWidth={'100%'}></Image>
        {/* <Image src={headerImage} maxWidth={'100%'}></Image> */}
      </AspectRatioBox>

      <Box textAlign="left" lineHeight={1.6}>
        <Box textAlign={'center'}>
          <Heading mb={3} size={'xl'} fontWeight={500}>
            Traditional Nigerian Wedding Ceremony
          </Heading>
          <Text>
            Marriott Hotel & Conference Center <br></br>
            3501 University Blvd E <br></br>
            Hyattsville, MD 20783
          </Text>
          <Text>
            Saturday, September 4th, 2021 <br></br>6 - 11 PM
          </Text>
        </Box>
        <Box textAlign={'center'}>
          <Heading mb={3} size={'xl'} fontWeight={500}>
            Anniversary Wedding Reception
          </Heading>
          <Text>
            Camelot by Martin's <br></br>
            13901 Central Ave <br></br>
            Upper Marlboro, MD 20774
          </Text>
          <Text>
            Sunday, September 5th, 2021 <br></br>5 - 10 PM
          </Text>
        </Box>

        <Divider></Divider>
        <Box textAlign={'center'}>
          <Text>
            By invitation <br></br>& Adults only
          </Text>
          <Divider></Divider>
          <Text mb={0}>RSVP before August 6th, 2021</Text>
        </Box>

        <Link
          href={'https://forms.gle/jfjHDR68PJMCQH476'}
          _hover={{
            color: '#dfbc76',
            textDecoration: 'underline',
          }}
          target="_blank"
          color={'#33170b'}
        >
          <Heading
            mb={0}
            mt={0}
            fontSize={32}
            fontWeight={'500'}
            textAlign="center"
            fontFamily={'centaur'}
            // letterSpacing="1px"
          >
            Click here to RSVP
          </Heading>
        </Link>
      </Box>
    </>
  );
};

export default Homepage;
