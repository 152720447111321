import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/core';

const DetailsPage = () => {
  return (
    <>
      <Heading
        fontWeight={500}
        fontSize={['3rem', '3.25rem']}
        lineHeight={1}
        textAlign={'center'}
        margin={'32px auto 16px'}
      >
        Details
      </Heading>

      <Box>
        <Heading
          textAlign="center"
          fontFamily={'centaur'}
          fontSize="26px"
          mb={3}
          size={'xl'}
          fontWeight={500}
        >
          Traditional Nigerian Wedding Ceremony (Saturday)
        </Heading>
        <Text textAlign="justify">
          The traditional Yoruba wedding/engagement ceremony is a celebration of
          two families coming together, joined by the relationship between the
          bride and groom. During the ceremony, there is typically at least one
          master of ceremonies (<i>alaga</i>) who leads the festivities, along
          with a drummer playing a “talking drum” (<i>gangan</i>). The alaga
          will likely use a mix of Yoruba and English throughout the ceremony.
          The beginning of the ceremony is marked by the entrance of the bride’s
          and groom’s families, who will dance to their seats in the front of
          the room. The families read proposal and acceptance letters
          symbolizing that both families gladly accept the union of their
          children. The groom then dances in with his friends, who all wear
          matching outfits (<i>aso ebi</i>). The alaga leads the groom and his
          aso ebi squad through demonstrations of respect for the bride’s family
          before the groom takes his seat on the stage. The bride then dances in
          with her own aso ebi squad, and the alaga guides her through
          demonstrations of acceptance of the wedding proposal before she joins
          the groom on the stage. Ceremonial gifts are exchanged, the cake is
          cut, and dinner is served. Each part of the ceremony is interspersed
          with singing, drumming, prayer, and--of course--dancing. Be ready to
          join the couple on the dance floor, and feel free to bring dollar
          bills to join the tradition of “spraying money” on the celebrants,
          their families, and other guests!
        </Text>

        <Text>Dress code: Nigerian attire or other colorful formal attire</Text>

        <Heading
          textAlign="center"
          fontFamily={'centaur'}
          fontSize="26px"
          mb={3}
          size={'xl'}
          fontWeight={500}
        >
          Anniversary Wedding Reception (Sunday)
        </Heading>

        <Text textAlign="justify">
          Kevin and Tolu had the great joy of joining in matrimony during a
          private ceremony on September 6, 2020. They are now looking forward to
          celebrating their marriage at their postponed wedding reception, on
          September 5, 2021--one day before their first wedding anniversary!
        </Text>

        <Text>Dress code: Formal/Black-tie optional</Text>

        <Heading
          textAlign="center"
          fontFamily={'centaur'}
          fontSize="26px"
          mb={3}
          size={'xl'}
          fontWeight={500}
        >
          Coronavirus Considerations
        </Heading>

        <Text textAlign="justify">
          To prioritize your health and ours, we very strongly recommend that
          all our invited guests be fully vaccinated against COVID-19 prior to
          attending our wedding events. If you will not be fully vaccinated by
          then for any reason, we ask that you would be tested for COVID-19
          within 3 days prior to September 4th and attend only if you receive a
          negative result. Mask use will be required at both events, so please
          bring your masks and feel free to bring face shields, as well.
        </Text>

        <Text textAlign="justify">
          These recommendations are subject to change as we continue to follow
          CDC and local guidelines, and we will update you further as needed.
        </Text>
      </Box>
    </>
  );
};

export default DetailsPage;
