import React from 'react';
import { Box, Heading, Link, Text, Image } from '@chakra-ui/core';
import weddingMap from '../assets/wedding_map.jpg';

const AccomodationsPage = () => {
  return (
    <>
      <Heading
        fontWeight={500}
        fontSize={['3rem', '3.25rem']}
        lineHeight={1}
        textAlign={'center'}
        margin={'32px auto 16px'}
      >
        Accommodations
      </Heading>
      <Box width="100%">
        <Text textAlign="justify">
          A block of rooms has been reserved for our guests at a discounted rate
          ($173 per night) at the Marriott Hotel on the premises of our Nigerian
          wedding ceremony venue. The rooms will be available at this rate on
          the nights of September 3rd, 4th, and 5th. To secure the group rate,
          kindly reserve your room before August 16, 2021, by clicking the link
          below (preferred) or by calling 301-985-7300, and reference the Lufkin
          Nigerian Engagement Ceremony. You are also welcome to secure other
          accommodations in the area; we recommend looking in the northern and
          central regions of Prince George’s County, Maryland.
        </Text>

        <Box textAlign="center" maxWidth="550px" margin="auto">
          <Text>
            <Link
              target="_blank"
              textDecoration="underline"
              href="https://www.marriott.com/events/start.mi?id=1622733702506&key=GRP
            "
            >
              Click here to reserve a room
            </Link>
          </Text>

          <Text>
            Hotel details:<br></br>
            College Park Marriott Hotel & Conference Center<br></br>
            3501 University Blvd E<br></br>
            Hyattsville, MD 20783<br></br>
            Guest services: 301-985-7300<br></br>
            Parking: Free garage onsite
          </Text>

          <Link
            width={'100%'}
            display="block"
            outline="none"
            target="_blank"
            href="https://www.google.com/maps/d/u/0/edit?mid=1C_ZdMpXG9MkeDNF3LDcEMPBvcJB7R19d&usp=sharing"
          >
            <Image src={weddingMap} width="100%" /></Link>

          <Text marginTop="4px">
            Click the image above for an interactive map of our venues and the
            above airports.
          </Text>

          <Text>
            For airports, we recommend using Ronald Reagan Washington National
            Airport (DCA) in Arlington, VA, or Baltimore/Washington
            International Thurgood Marshall Airport (BWI) in Baltimore, MD.
            Dulles International Airport (IAD) in Dulles, VA, is another option
            in the DC/MD/VA area but is farther away from our events than the
            other two airports.
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default AccomodationsPage;
